
import { defineComponent } from "vue";
import InputText from "primevue/inputtext";
import Dialog from "primevue/dialog";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import TextArea from "primevue/textarea";
import store from "@/store";
import useVuelidate from "@vuelidate/core";
import Utils from "@/utility/utils";

//types
import Response from "@/types/response/response";
import SalesOrder, { LisItems } from "@/types/salesorder";
import { Fdict, Field } from "@/types/fdict";

// Services

import SalesService from "@/services/SalesService";
import CustomerService from "@/services/CustomerService";

const customerService = new CustomerService();
const salesService = new SalesService();

export default defineComponent({
  name: "StageDialog",
  props: {
    customer: Object,
    modelValue: String,
    show: Boolean,
    order: Object,
    readOnly: Boolean,
    orderId: String,
    showLoadExistingQuoteBtn: Boolean,
    BulkView: Boolean,
  },
  emits: ["hide", "onSave"],
  created() {
    this.hidden = this.show;
  },
  updated() {
    if (this.order != null) {
      this.salesOrder = this.order as SalesOrder;
    }
  },
  components: {
    InputText,
    Dialog,
    Button,
    Calendar,
    TextArea,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },

  data() {
    return {
      salesOrder: {} as SalesOrder,
      salesOrderFdict: {} as Fdict,
      part: {
        quantity: 1,
        number: "",
        date: "",
      },
      showPartModal: false,
      isLoadingQuote: false,
      isLoadingOrder: false,
      showPartsByPriceCode: false,
      showAddExistingQuoteDialog: false,
      showAddExistingOrderDialog: false,
      existingSOQuote: "",
      existingOrder: "",
      isLoadQuoteFormSubmitted: false,
      isLoadOrderFormSubmitted: false,
      visibleSignatureDialog: false,
      statusMessage: "",
      isOrderEmpty: false,
      discount: 0,
      price_code: "",
      status: [
        {
          description: "Closed",
          value: "C",
        },
        {
          description: "New",
          value: "N",
        },
        {
          description: "Backorder",
          value: "B",
        },
      ],
      newSalesOrderCust: "",
      newSalesOrderContact: "",
      partName: "",
      partLoading: false,
      submitLoading: false,
      isFormSubmitted: false,
      hidden: true,
      priceCodeParts: [] as Array<any>,
    };
  },
  computed: {
    header(): string {
      //return `Order #${this.salesOrder.so_id}`;
      if (this.salesOrder.WEB_STATUS == "BULK") {
        return "Bulk Item";
      } else {
        return `Order #${this.salesOrder.ORIGINAL_ID_VIEW}`;
      }
    },
    customFields(): Array<Field> {
      if (this.salesOrderFdict?.field_no_items) {
        return this.salesOrderFdict.field_no_items.filter((item) => {
          return (
            +item.field_no >= 80 &&
            +item.field_no <= 100 &&
            item.control_field != "26"
          );
        });
      } else {
        return [];
      }
    },
    customFieldsLineItems(): Array<Field> {
      if (this.salesOrderFdict?.field_no_items) {
        return this.salesOrderFdict.field_no_items.filter((item) => {
          return (
            +item.field_no >= 80 &&
            +item.field_no <= 100 &&
            item.control_field === "26"
          );
        });
      } else {
        return [];
      }
    },
  },
  methods: {
    async submit() {
      this.isFormSubmitted = true;

      this.submitLoading = true;
      if (this.salesOrder.so_id == null) {
        this.salesOrder.custom_fields.WEB_ACTION = "NEW";
      } else {
        this.salesOrder.custom_fields.WEB_ACTION = "UPDATE";
      }

      salesService
        .postOrder(this.salesOrder, "SOH.WEB")
        .then((response) => {
          this.submitLoading = false;
          this.hidden = false;
          let saleId = (response as any).recordId;
          this.salesOrder.so_id = saleId;
          this.$emit("onSave", this.salesOrder);
          const notification = {
            message: `Successfully Updated Record`,
            type: "success",
          };
          store.dispatch("notification/add", notification);
        })
        .catch((error) => {
          this.submitLoading = false;
          this.isFormSubmitted = false;
        });
    },
    formatDate(date: Date, field: string) {
      const formattedString = Utils.formatDate(date);
      switch (field) {
        case "PART":
          this.part.date = formattedString;
          break;
        case "CLOSE_DATE":
          this.salesOrder.close_date = formattedString;
          break;
        case "HOLD_DATE":
          this.salesOrder.hold_date = formattedString;
          break;
        case "DATE":
          this.salesOrder.date = formattedString;
          break;
      }
    },
  },

  watch: {
    show(newShow, oldShow) {
      if (!newShow) {
        //  this.initSalesOrder();
      }
      this.hidden = newShow;
    },
  },
});
